<template>
    <section class="result-title">
        <div class="heading">
            <label><slot></slot></label>
            <h1>
                <span><b-icon icon="pencil" class="icon"></b-icon> {{ title }}</span>
            </h1>
        </div>

        <p v-if="desc">{{ desc }}</p>
        
        <div class="total-result">
            พบสินค้า <i>{{ totalResult }}</i> รายการ
        </div>
    </section>
</template>

<script>
export default {
    props: {
        title: String,
        desc: String,
        totalResult: [ Number, String ]
    }
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.result-title {
    position: relative;

    .heading {
        display: flex;
        flex-wrap: wrap;
        padding-right: 140px;

        @media only screen and (max-width: $screenMedium) {
            display: block;
            padding-right: 0;
        }

        label {
            font-size: 14px;
            padding: 3px 20px;
            font-family: 'Open Sans', 'Kanit';
            margin: 0;
            color: #555;
            background: $mainColor;
            font-weight: bold;
            text-align: center;
            margin-right: 15px;
            @include borderRadius(1000px);
        }

        h1 {
            display: flex;
            flex-wrap: wrap;
            margin: 0;

            span {
                display: block;
                font-size: 14px;
                font-weight: bold;
                color: #000;
                margin-top: 3px;

                @media only screen and (max-width: $screenMedium) {
                    margin: 10px 0 7px;
                    font-size: 13px;
                    width: 100%;
                }

                .icon {
                    margin-right: 5px;
                    opacity: 0.7;
                }
            }
        }
    }

    p {
        font-size: 12px;
        line-height: 15px;
        color: #888;
        padding-left: 150px;

        @media only screen and (max-width: $screenMedium) {
            padding-left: 0;
        }
    }

    .total-result {
        position: absolute;
        top: 3px;
        right: 0;
        font-size: 13px;
        white-space: nowrap;
        color: #000;

        i {
            font-weight: bold;
            font-style: normal;
            color: #cc0000;
        }
    }
}
</style>