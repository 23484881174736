<template>
	<div class="favorite">
		<div class="container">
			<gadget-ads-banner :banner="topBanner" name="bannerPageFavorite" :isBannerReady="isContentReady"></gadget-ads-banner>

			<div class="content-container mt-3">
				<div class="filter" v-show="isContentReady && resultList.length > 0">
					<div class="loading" v-if="!isContentReady">
						<loader-content :isDisplay="true"></loader-content>
					</div>
					<template v-if="isContentReady">
						<div class="content-mobile-filter-modal" :class="{ 'active': isShowMobileFilter }" @click="toggleFilterMobile()">
							<div class="box" @click.stop>
								<perfect-scrollbar>
									<div class="filter-title">
										<b-icon icon="sliders" class="icon"></b-icon> กรองผลการค้นหา <b-icon icon="x" @click="toggleFilterMobile()" class="close-icon"></b-icon>
									</div>
									<filter-search
										v-if="filterOptions && tags"
										ref="filterSearch"
										:filterOptions="filterOptions"
										:tagSelections="tags"
										@filterResult="changeFilter($event)">
									</filter-search>
								</perfect-scrollbar>
								<div class="operation-panel">
									<div class="wrapper">
										<button class="btn btn-reset btn-outline-secondary" @click="resetFilter()">รีเซ็ต</button>
										<button class="btn btn-main d-xl-none" @click="toggleFilterMobile()">ดูสินค้า {{ resultList.length }} รายการ</button>
									</div>
								</div>
							</div>
						</div>
					</template>
				</div>
				<div class="content-body">
					<div class="content-mobile-filter">
						<button @click="toggleFilterMobile()"><b-icon icon="sliders" class="filter-icon"></b-icon> กรองผลการค้นหา</button>
					</div>

					<div class="list">
						<div class="loading" v-if="!isResultReady">
							<div class="item">
								<loader-content :isDisplay="true"></loader-content>
							</div>
						</div>
						<template v-if="isResultReady">
							<product-result-title title="รายการสินค้าที่สนใจ" :totalResult="resultList.length">ผลการค้นหา</product-result-title>
							<product-favorite class="mt-3" :products="resultList" @removeFavorite="removeFavorite($event)"></product-favorite>
						</template>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Helper from '@/services/helper';
import ProductService from '@/services/productService';
import GadgetAdsBanner from '@/components/gadget/AdsBanner';
import FilterSearch from '@/components/gadget/FilterSearch';
import ProductFavorite from '@/components/product/ProductFavoriteList';
import ProductResultTitle from '@/components/product/ResultTitle';
import RouterPath from '@/router/path';
import UserService from '@/services/userService';

export default {
	components: {
		GadgetAdsBanner,
		FilterSearch,
		ProductFavorite,
		ProductResultTitle
	},
	data() {
		return {
			isContentReady: false,
			isResultReady: false,
			isShowMobileFilter: false,
			topBanner: null,
			tags: [],
			filterOptions: [],
			resultList: [],
			filter: null
		};
	},
	mounted() {
        this.getContent();
		this.getFavoriteList();
    },
    methods: {
        async getContent() {
            this.isContentReady = false;

            const result = await ProductService.getFavoriteContent();

			if (result.status == 401) {
				UserService.clearUserToken();
				this.$router.push(RouterPath.LOGIN);
			}

			this.topBanner = result.data.ads;
			this.filterOptions = result.data.filter;
            this.isContentReady = true;
        },
		async getFavoriteList() {
            this.isResultReady = false;

			const param = this.setupParam();
            const result = await ProductService.getFavoriteProducts(param);

			this.resultList = result.data.favorites;
            this.isResultReady = true;
        },
		setupParam() {
			const param = {
				filter: this.filter
			};

			return param;
		},
		async removeFavorite(data) {
			let loader = this.$modalLoader.render();

			await ProductService.removeFavorite(data);

			this.getFavoriteList();
			loader.hide();
		},
		changeFilter(data) {
			this.filter = data.filter;
			this.getFavoriteList();
		},
		toggleFilterMobile() {
			this.isShowMobileFilter = !this.isShowMobileFilter;
            this.$root.$emit('rootSetContentScrollable', this.isShowMobileFilter);
		},
		resetFilter() {
			this.$refs.filterSearch.resetFilter();
		}
    },
	metaInfo() {
		const title = 'รายการสินค้าที่ถูกใจ | YELLOWTiRE';
		const desc = 'รายการสินค้าที่ถูกใจในเว็บไซต์ YELLOWTiRE';
		const image = location.origin + Helper.metaDefault().logo;
		
        return {
            title: title,
            meta: [
				{ vmid: 'author', name: 'author', content: Helper.metaDefault().author },
				{ vmid: 'publisher', name: 'publisher', content: Helper.metaDefault().publisher },
				{ vmid: 'keyword', name: 'keywords', content: Helper.metaDefault().keyword },
                { vmid: 'desc', name: 'description', content: desc },
                { vmid: 'og-url', property: 'og:url', content: location.href },
                { vmid: 'og-title', property: 'og:title', content: title },
                { vmid: 'og-desc', property: 'og:description', content: desc },
                { vmid: 'og-type', property: 'og:type', content: 'website' },
                { vmid: 'og-image', property: 'og:image', content: image },
                { vmid: 'twitter-description', property: 'twitter:description', content: desc },
                { vmid: 'twitter-title', property: 'twitter:title', content: title },
                { vmid: 'twitter-image', property: 'twitter:image', content: image }
            ]
        }
	}
}
</script>

<style lang="scss" scoped>
.favorite {
	padding: 15px 0;

	.list {
		.loading {
			.item {
				height: 500px;
			}
		}
	}
}
</style>