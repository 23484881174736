<template>
    <div>
        <section class="favorite-grid">
            <div class="product"
                v-for="(item, index) in products"
                :key="index">
                <favorite-box
                    @removeFavorite="removeFavorite($event)"
                    :product="item">
                </favorite-box>
            </div>
        </section>

        <div class="no-data" v-if="products.length === 0">
            <b-icon icon="box-seam"></b-icon>
            <span>ไม่มีรายการสินค้าที่ชอบ</span>
        </div>
    </div>
</template>

<script>
import FavoriteBox from '@/components/product/FavoriteBox';

export default {
    components: {
        FavoriteBox
    },
    props: {
        products: {
            type: Array,
            required: true
        }
    },
    methods: {
        removeFavorite(data) {
            this.$emit('removeFavorite', data);
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.favorite-grid {
    @include gridContentWrapper(0 -5px);
    justify-content: flex-start;

    .product {
        display: block;
        padding: 0 5px 5px;
        width: 25%;

        @media only screen and (max-width: $screenSmall) {
            width: 33.33333%;
        }

        @media only screen and (max-width: $screenExtraSmall) {
            width: 50%;
        }
    }
}
</style>